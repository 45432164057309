/*
* @Author: 曹俊杰
* @Date: 2024-09-22 14:57:49
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-22 22:43:19
*/推广
<template>
    <div class="box">
        <div class="alert">
            <div class="el-icon-ali-jinggao" style="margin-right:8px;"></div>
            <div>邀请用户注册并完成企业认证获收益20元，被邀请用户进行充值可获得相对应等级的返润收益</div>
        </div>
        <div class="top">
            <div class="title">推广收益</div>
            <div class="itembox">
                <div class="item">
                    <div class="item-t">我的收益</div>
                    <div class="item-c">0.00</div>
                    <div class="item-b">
                        <span>转入企业余额</span>
                        <span style="margin: 0 10px;">|</span>
                        <span>提现</span>
                    </div>
                </div>
                <div class="item" style="margin: 0 22px;">
                    <div class="item-t">我的推广用户</div>
                    <div class="item-c">
                        <span>8</span>
                        <span class="as">人</span>
                    </div>
                    <div class="item-b">
                        <span>生成推广海报</span>
                        <span style="margin: 0 10px;">|</span>
                        <span>复制邀请码</span>
                    </div>
                </div>
                <div class="item lastItem">
                    <div class="item-t">我的等级</div>
                    <div class="item-c">
                        <span>初级（返润5%）</span>
                    </div>
                    <div class="item-b">
                        <span>如何提升推广等级？</span>
                    </div>
                    <img class="bg-image" src="~/image/accountCenter/accountCenter_bg_card.svg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <div class="title">推广收益明细</div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.box {
    .alert {
        border-radius: 2px;
        background: #EFF6FF;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 16px;
        color: #0062FF;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 185.714% */
    }

    .top {
        background: #fff;
        padding: 20px;
        border-radius: 6px;

        .title {
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
        }

        .itembox {
            display: flex;

            .item {
                border-radius: 4px;
                background: #F3F6F8;
                width: calc((100% - (22px*2))/3);
                padding: 20px;

                &-t {
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                &-c {
                    font-family: "DIN Alternate";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 10px;

                    .as {
                        color: #999;
                        font-family: "Source Han Sans CN";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-left: 8px;
                    }
                }

                &-b {
                    color: #0062FF;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }

            .lastItem {
                color: #fff;
                border-radius: 10px;
                background: linear-gradient(180deg, #0062FF 0%, #629EFF 100%);
                background-repeat: no-repeat;
                position: relative;

                .item-c {
                    font-family: "Source Han Sans CN";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-top: 19px;
                }

                .item-b {
                    color: #FFF;
                    font-family: "Source Han Sans CN";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 19px;
                }

                .bg-image {
                    width: 120px;
                    position: absolute;
                    top: 0;
                    right: 20px;
                }
            }
        }
    }

    .content {
        padding: 0 20px;
        margin-top: 16px;
        background: #fff;
        border-radius: 6px;
        .title {
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 60px;
        }
    }

}
</style>